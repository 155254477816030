@import url("https://fonts.googleapis.com/css2?family=Righteous&family=Work+Sans:wght@100;300;400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");
:root {
  --espacios_title_h2: 10px;
  --espacios-contenido_line: 45px;
  --size_titulos: 48px;
  --size_parrafos: 18px;
  --size_margenes: 60px;
  --color_font_title: #f2f2f2cb;
  --primary-bg-color-bold: #1e2326;
  --primary-bg-color: #252A2E;
  --primary-bg-color-light: #363E43;
  --secondary-bg-color: #00886e;
  --secondary-bg-color-light: #38b29c;
  --secondary-bg-color-light-faint: #d4ede9;
  --tertiary-bg-color: #17191A;
  --tertiary-bg-color-light: #26282a;
  --font_title1: "Righteous";
  --font_title2: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  --font_title3: "Righteous";
  --font_general: "Paytone One","sans-serif";
}
