/* ----------------------------------------------*/
/*                    HOME                     */
/* ----------------------------------------------*/
/*contenedor home*/
.container {
    background: linear-gradient(to top, rgb(30, 35, 38), rgba(30, 35, 38, 0.817)),
      url(../../assets/webp/Font.webp);
    background-size: cover;
    padding: 0rem 7rem;
  }
  /*Tamaño img USUARIO*/
  .right__content img {
    height: 100%;
    width: 100%;
  }
  /*posicion de pantalla completa*/
  .home__page {
    height: 98vh;
    width: 100%;
    position: relative;
  }
  /*fila de imagen y texto*/
  .div_2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
  }
  /*subtitulo*/
  .txt {
    color: rgb(153, 151, 151);
    margin-bottom: 30px;
    font-size: 25px;
    font-weight: bold;
  }
  /*tamaño imagen*/
  .imgg {
    width: 480px;
    height: 480px;
    overflow: hidden;
    margin-left: 70px;
  }
  /*imagen usuario*/
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /*mensaje HOLA YO SOY*/
  .message__bubble {
    display: inline-block;
    position: relative;
    width: 180px;
    height: auto;
    background-color: var(--secondary-bg-color);
  }
  /*triangulo*/
  .triangle:after {
    content: "";
    position: absolute;
    left: 40%;
    border: 15px solid;
    border-color: var(--secondary-bg-color) transparent transparent transparent;
  }
  /*texto mensaje*/
  .message__text {
    padding: 1rem;
    font-size: 20px;
    text-align: center;
    font-family: var(--font_title1);
  }
  
  /*contenedor botones home*/
  .home__buttons button {
    font-family: var(--font_title1);
    margin-left: 3px;
    padding: 1rem 2rem;
    color: var(--color_font_title);
  }
    /* Enlaces*/
    .btn-header.active {
      color: var(--secondary-bg-color-light);
    }
  /*enlaces botones*/
  .home__buttons a {
    background-color: var(--secondary-bg-color);
    font-family: var(--font_title1);
    font-size: 18px;
    color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 10px;
  }
  .home__buttons .des {
    background-color: var(--secondary-bg-color);
    font-family: var(--font_title1);
    font-size: 18px;
    color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border: none;
    margin-right: 4px;
    cursor: pointer;
  }
  .cursor-pointer {
    font-family: var(--font_title1);
    cursor: pointer;
  }
  /*boton mi trabajo*/
  .home__buttons button {
    background-color: transparent;
    border: 1px solid #fff;
    border-width: thin;
    font-size: 0.9rem;
    color: #fff;
    padding: 1rem 1.5rem;
  }
  @media screen and (max-width: 1400px) {
    .imgg {
      margin-top: 100px;
      width: 380px;
      height: 380px;
      margin-left: 30px;
    }
    .txt {
      font-size: 20px;
    }
    .h11 {
      font-size: 50px;
    }
  }
  @media screen and (max-width: 1200px) {
    .imgg {
      width: 380px;
      height: 380px;
      margin-left: 20px;
    }
    .txt {
      font-size: 15px;
    }
    .h11 {
      font-size: 45px;
    }
  }
  @media screen and (max-width: 1000px) {
    .imgg {
      width: 380px;
      height: 380px;
      margin-left: 20px;
    }
    .txt {
      font-size: 15px;
    }
    .h11 {
      font-size: 45px;
    }
  }
  @media screen and (max-width: 900px) {
    .div_2 {
      display: flex;
      flex-direction: column;
      height: 95vh;
    }
    :root {
      --margenes: 45px;
    }
    .h11{
      margin-top: 24px;
    }
  }
  @media screen and (max-width: 700px) {
    .imgg {
      margin-top: 30px;
      width: 335px;
      height: 340px;
      margin-left: 0px;
      margin-bottom: 10px;
    }
    .txt {
      font-size: 17px;
    }
  }
  @media screen and (max-width: 500px) {
    .divv {
      width: 360px;
      margin-top: 120px;
    }
  .column-container {
    display: flex;
    flex-direction: column;
  }
  .es .h11 {
    margin-top: 24px;
    font-size: 41px;
  }
  :root {
    --espacios-contenido: 25px;
  }
}