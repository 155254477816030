/* ----------------------------------------------*/
/*                 ABOUT ME                      */
/* ----------------------------------------------*/
/*fondo sobre mi contenedor*/
.sobremi {
  background-color: var(--tertiary-bg-color);
  color: #f2f2f2cb;
  padding: 50px 20px;
}
/*contenedor inteerno*/
.sobremi .contenido-seccion {
  max-width: 1100px;
  margin: auto;
}
/*title*/
.sobremi h2 {
  font-size: var(--size_titulos);
  font-family: var(--font_title1);
  text-align: center;
  padding: 20px 0;
}
/*parrafo descripcion*/
.sobremi .t2 {
  text-align: justify;
  font-size: var(--size_parrafos);
  font-family: var(--font_title3);
}
/*espacio contenedor descripcion*/
.sobremi .contenido-seccion p {
  font: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}
/*span color*/
.sobremi .contenido-seccion p span {
  color: var(--secondary-bg-color-light);
  font-size: bolder;
}
/*fila*/
.sobremi .fila {
  display: flex;
}
/*fila clumnas*/
.sobremi .fila .col {
  width: 50%;
}
/*subtitulo*/
.sobremi .fila .col h3 {
  color: #fff;
  font-size: 28px;
  font-family: var(--font_title1);
  margin-bottom: 25px;
}
/*lista */
.sobremi .fila .col ul {
  list-style: none;
}
.des {
  font-family: var(--font_title1);
}
/*lista datos*/
.sobremi .fila .col ul li {
  margin: 12px 0;
  font-family: var(--font_title2);
}
/*names list*/
.sobremi .fila .col ul li strong {
  display: inline-block;
  color: var(--secondary-bg-color-light);
  margin-left: 7px;
  font-family: var(--font_title1);
  width: 130px;
}
/*span desarrollador*/
.sobremi .fila .col ul li span {
  background-color: var(--secondary-bg-color-light);
  color: #fff;
  font-family: var(--font_title1);
  padding: 8px;
  border-radius: 5px;
}
/*conetenedor intereses*/
.sobremi .fila .col .contenedor-intereses {
  display: flex;
  flex-wrap: wrap;
}
.sobremi li a {
  text-decoration: none;
  color: #f2f2f2cb;
}
.sobremi li a:hover {
  text-decoration: none;
  color: var(--secondary-bg-color-light);
}
/*cuadros*/
.sobremi .fila .col .contenedor-intereses .interes {
  width: 100px;
  height: 100px;
  background-color:  var(--primary-bg-color);
  border-radius: 10px;
  margin: 0px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
/*HOVER*/
.sobremi .fila .col .contenedor-intereses .interes:hover {
  background-color: var(--secondary-bg-color-light);
  cursor: pointer;
}
/*ICONOSS*/
.sobremi .fila .col .contenedor-intereses .interes i {
  font-size: 30px;
  margin-bottom: 10px;
}
/*DESCARGAR CV BOTON*/
.sobremi button {
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #fff;
  width: fit-content;
  display: block;
  margin: 20px auto;
  padding: 10px 22px;
  font-size: 16px;
  color: #fff;
  position: relative;
  z-index: 10;
}
.cv i {
  margin-left: 10px;
}
/*Efecto boton*/
.sobremi .des .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--secondary-bg-color-light);
  z-index: -1;
  transition: 1s;
}
.sobremi button:hover .overlay {
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .sobremi .fila {
    display: block;
  }
  .sobremi .fila .col {
    width: fit-content;
  }
  .skills .fila .col {
    width: 100%;
  }
  
}

@media screen and (max-width: 700px) {
  .sobremi .fila .col .contenedor-intereses {
    width: 100%;
}

}
@media screen and (max-width: 500px) {
  .sobremi .fila .col ul li strong {
    width: 95px;
  }
}