/* ----------------------------------------------*/
/*                    FOOTER                     */
/* ----------------------------------------------*/
/*contenedor footer*/
footer {
    background-color: var(--primary-bg-color);
    color: #fff;
    padding: 0px;
    text-align: center;
    position: relative;
    width: 100%;
  }
  /*redes boton*/
  footer .redes {
    margin-bottom: 20px;
  }
  /*iconos diseño*/
  footer .redes a {
    color: #fff;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #fff;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin: 40px 5px;
    font-size: 20px;
    transition: transform 0.3s ease-in-out;
  }
  footer .redes a:hover {
    color: var(--secondary-bg-color-light);
    border: 1px solid var(--secondary-bg-color-light);
    transform: scale(1.1);
  }
  /*arriba*/
  footer .arriba {
    display: block;
    width: 50px;
    height: 50px;
    background-color: var(--secondary-bg-color-light);
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
    border-radius: 50%;
    line-height: 50px;
    font-size: 18px;
    scroll-behavior: smooth;
  }
  .arriba:hover{
    cursor: pointer;
  }
  
  /*------------------------------------*/
  /* EXTRA */
  /*------------------------------------*/
  
  /* Estilos para los campos requeridos */
  .required {
    color: red;
    margin-left: 5px;
  }
  
  /* Estilos para resaltar campos obligatorios no completados */
  input:required:invalid, textarea:required:invalid {
    border-color: red;
  }
  
  /* Estilos para mostrar mensaje de error */
  input:required:invalid + label::after, textarea:required:invalid + label::after {
    content: " (Campo obligatorio)";
    color: red;
    font-size: 90%;
  }