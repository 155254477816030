.contenedor-header {
  background: var(--primary-bg-color);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  height: 80px;
  position: fixed;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
.contenedor-header header {
  max-width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  height: 80px;
}
.contenedor-header header .logo a {
  font-family: "Righteous";
  font-size: 36px;
  color: var(--secondary-bg-color);
  text-decoration: none;
}
.contenedor-header header ul {
  display: flex;
  list-style: none;
}
.contenedor-header header nav ul li a {
  text-align: none;
  color: #ffffffbc;
  font-family: var(--font_title1);
  margin: 0 15px;
  padding: 3px;
  transition: 0.5s;
  text-decoration: none;
  font-size: 17px;
}
.contenedor-header header nav ul li a:hover {
  color: var(--secondary-bg-color);
}
.nav-responsive {
  background-color: var(--secondary-bg-color);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}
/* Contenedor header*/
header {
  width: 100%;
  height: 80px;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

/* Logotipo*/
.logo,
.logo img {
  height: 70px;
}
/* Logotipo:hover*/
.logo,
.logo img:hover {
  cursor: pointer;
}
/* Enlaces*/
.btn-header.active {
  color: var(--secondary-bg-color);
}

/* Contenedor textos*/
.textos {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
  overflow: hidden;
  text-align: center;
  font-family: "open sans";
}

/* Boton contratame*/

.hireMe__button {
  display: inline-block;
}

.hireMe__button a {
  display: inline-block;
  background-color: var(--secondary-bg-color);
  color: white; 
  padding: 1rem 1.5rem;
  font-family: var(--font_title1);
  font-size: 20px;
  font-weight: 200;
  text-decoration: none;
  border: none;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out; 
}

.hireMe__button a:hover {
  transform: scale(1.05); 
}


#nav-responsive {
  background: #2d3438;
  position: absolute;
  top: 80px; 
  right: 0;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

#nav-responsive ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column; 
  align-items: center; 
}

#nav-responsive li {
  border-bottom: 1px solid #fff;
  width: 100%;
  padding: 6px 0;
}

#nav-responsive a {
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  display: block; 
  text-align: center; 
  padding: 10px; 
}
@media screen and (max-width: 1400px) {
  nav {
    display: none;
  }
  .nav-responsive {
    display: block;
  }
  nav.responsive {
    display: block;
    position: absolute;
    right: 0;
    top: 75px;
    width: 180px;
  }
  nav.responsive ul {
    display: block !important;
  }
  nav.responsive ul li {
    border-bottom: 1px solid #fff;
    padding: 10px 0;
  }
  .logo,
  .logo img {
    height: 80px;
  }   .contenedor-nav {
    width: 100%;
  }
  nav .enlaces a {
    font-size: 20px;
  }
  .nav-responsive {
    display: block;
  }
  nav.responsive {
    display: block;
    position: absolute;
    right: 0;
    top: 75px;
    width: 180px;
  }
  nav.responsive ul {
    display: block !important;
  }
  nav.responsive ul li {
    border-bottom: 1px solid #fff;
    padding: 10px 0;
  }
  .hireMe__button {
    margin-left: 500px;
  }
}

@media screen and (max-width: 1200px) {
  .logo,
  .logo img {
    height: 70px;
  }
  .hireMe__button {
    margin-left: 300px;
  }
}

@media screen and (max-width: 1000px) {
  .contenedor-header header {
    width: 100%;
  }
  .hireMe__button {
    margin-left: 200px;
  }
}

@media screen and (max-width: 900px) {
  .logo,
  .logo img {
    height: 50px;
  }
  .hireMe__button {
    margin-left: 100px;
  }
  .enlaces a {
    display: block;
    width: 100%;
    height: 50px;
    padding: 20px;
    text-align: center;
    color: #fff;
  }
  .textos > h1 {
    font-size: 70px;
  }
  .textos > h2 {
    font-size: 35px;
  }
}
@media screen and (max-width: 700px) {
  .hireMe__button {
    margin-left: 10px;
  }
  .contenedor-header header {
    max-width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .hireMe__button a {
    padding: 0.5rem 1rem;
    border-radius: 7px;
  }
  .textos > h1 {
    font-size: 70px;
  }
  .textos > h2 {
    font-size: 28px;
  }
  .aa {
    margin-right: 10px;
  }
}