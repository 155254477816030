/* ----------------------------------------------*/
/*                 SKILLS                      */
/* ----------------------------------------------*/
/*FONDO skills*/
.skills {
    background-color:  var(--primary-bg-color);
    color: #fff;
    padding: 50px 20px;
  }
  /*contenedor skills*/
  .skills .contenido-seccion {
    max-width: 1100px;
    margin: auto;
  }
  /*title*/
  .skills h2 {
    font-size: var(--size_titulos);
    font-family: var(--font_title1);
    text-align: center;
    padding: 20px 0;
  }
  /*fila*/
  .skills .fila {
    display: flex;
  }
  /*columnas*/
  .skills .fila .col {
    width: 50%;
    padding: 0 20px;
  }
  /*subtitle*/
  .skills .fila .col h3 {
    font-size: var(--size_titulos);
    font-family: var(--font_title1);
    margin-bottom: 25px;
  }
  /*titulos negrita*/
  .skills .skill > span {
    font-family: var(--font_title1);
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    width: 200px;
    color: #f2f2f2e3;
  }
  /*barra*/
  .skills .skill .barra-skill {
    height: 8px;
    width: 80%;
    background-color:  var(--tertiary-bg-color);
    position: relative;
    margin-bottom: 30px;
  }
  /*progreso*/
  .skills .skill .progreso {
    background-color: var(--secondary-bg-color-light);
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
  }
    /*progreso*/
    .skills .skill .progreso .porcentaje {
      font-family: 'Courier New', Courier, monospace;
    }
  /*span sirculo*/
  .skills .skill .barra-skill span {
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: var(--secondary-bg-color-light);
    border-radius: 50px;
    line-height: 40px;
    text-align: center;
    top: -17px;
    right: -15px;
    font-size: 14px;
  }
  .cv a {
    font-weight: 300;
    font-family: var(--font_title1);
    text-decoration: none;
    border-bottom: 3px solid transparent;
    color: #ffffff;
  }
/* Skills.css */

.skills .barra-skill .progreso {
  width: 0%;
  animation: 2s habilidad forwards;
}

@keyframes habilidad {
  0% {
    width: 0%;
  }
  100% {
    width: var(--porcentaje);
  }
}


@media screen and (max-width: 1000px) {

  .skills .fila .col .barra-skill {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .skills .fila {
    display: block;
  }

  .skills .fila .col .barra-skill {
    width: 220%;
  }

}
@media screen and (max-width: 500px) {
.col h3{
  font-size: 34px !important;
}
}