/* ----------------------------------------------*/
/*                 TRABAJO                    */
/* ----------------------------------------------*/
/*fondo trabajo*/
.w {
  background-color: var(--primary-bg-color);
  color: #fff;
  padding: 50px 20px;
}
/*title*/
.w h2 {
  font-family: var(--font_title1);
  font-size: var(--size_titulos);
}
/*subtitle*/
.w p {
  color: var(--secondary-bg-color-light);
  font-family: var(--font_title1);
  font-size: 22px;
}
/*fila botones*/
.botones-work {
  overflow: hidden;
  padding-bottom: 34px;
  font-family: var(--font_title1);
}
/* Botones li*/
.botones-work li {
  display: inline-block;
  text-align: center;
  margin-left: 8px;
  font-size: 20px;
  margin-bottom: var(--espacios-contenido);
  padding: 6px 12px;
  border: 1px solid var(--secondary-bg-color);
  list-style: none;
  color: var(--secondary-bg-color);
}
/* Botones li hover*/
.botones-work li:hover {
  background: var(--secondary-bg-color);
  color: #fff;
  cursor: pointer;
}
/* Botones li active(una vez pulsado toma los atributos)*/
.botones-work .active {
  background: var(--secondary-bg-color);
  color: #fff;
}
/* Contenedor de imagenes*/
.galeria-work {
  display: flex;
  flex-wrap: wrap;
  justify-content: initial;
  gap: 20px;
}
/* Caja de imagenes y texto */
.cont-work {
  width: 31%;
  height: 300px;
  overflow: hidden;
  margin-bottom: var(--espacios-contenido);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3); 
}

.cont-work h4 {
  font-family: var(--font_title1);
  color: #ffffff8c;
  font-size: 20px;
}
/* Caja de imagenes */
.img-work {
  height: 90%;
  width: 100%;
  position: relative;
  display: inline-block;
}
/* Imagenes */
.img-work img {
  height: 100%;
  width: 100%;
  filter: brightness(0.7) blur(2px);
  transition: filter 0.3s;
  object-fit: cover;
}
.img-work img:hover {
  filter: brightness(1) blur(2px);
  cursor: pointer;
}
.img-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-family: var(--font_title1);
  color: rgb(167, 185, 189);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  z-index: 0;
}
.img-caption {
  cursor: pointer;
}
/* Texto */
.textos-work {
  height: 10%;
}
/* Texto h4 */
.textos-work h4 {
  line-height: 30px;
  font-weight: 300;
}

.link-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 1;
}

/**/
.modal-overlay {
  font-family: var(--font_title1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-overlay h2 {
  font-family: var(--font_title1);
  color: #fff;
  font-size: 40px;
}
.modal-content {
  background-color: var(--primary-bg-color);
  padding: 20px;
  border-radius: 8px;
  max-width: 930px;
  width: 100%;
  position: relative;
  padding: 20px;
  overflow: hidden;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 2px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
  color: #555;
}

.close-button:hover {
  color: #dddbdb;
}

.modal-content img {
  width: 98%;
  height: 55%;
  border-radius: 4px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  text-align: center;
}

.buttons-container a {
  display: inline-block;
  padding: 10px;
  background-color: var(--secondary-bg-color);
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  margin-left: 10px;
  transition: background-color 0.3s;
}

.buttons-container a:hover {
  background-color: var(--secondary-bg-color-light);
}

/**/
/* Estilos para el contenedor de aptitudes en el modal */
.modal-skills {
  bottom: 0;
  left: 0;
  margin: 20px;
  z-index: 1;
}

/* Estilos para los iconos de aptitudes */
.modal-skills div i {
  color: var(--primary-bg-color-light);
  padding: 5px;
  border-radius: 50%;
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
}

.modal-skills div {
  line-height: 70px;
  padding: 3px;
  border-radius: 50%;
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  margin-right: 10px;
  font-weight: bold;
}
.buttons-container {
  text-align: center;
}

.modal-enlaces {
  display: inline-block;
  margin: 10px; 
}.modal-container {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 20px;
  z-index: 1;
  display: flex;
}

.modal-enlaces {
  display: inline-block;
  margin: 10px;
  width: 40%;
}

.modal-skills {
  width: 60%;
}

.descripcion {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: justify;
}

.descripcion p {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 900px) {
  .cont-work {
    width: 48%;
    height: 290;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 700px) {
.modal-content {
  width: 95%;
}
.modal-overlay h2 {
  font-size: 25px;
}
}
@media screen and (max-width: 500px) {
  .cont-work {
    width: 100%;
    height: 290;
    margin-bottom: 10px;
  }
  .img-work img {
    height: 100%;
    width: 100%;
    filter: brightness(0.7) blur(2px);
    transition: filter 0.3s;
    object-fit: cover;
}
  .w p{
    font-size: 17px;
  }
  .modal-images img {
    width: 90%;
    max-width: none;
    margin-top: 10px;
    background-size: cover;
    background-position: center center;
  }
  .modal-t {
    font-size: 20px;
  }

  .modal-enlaces {
    display: flex;
    height: 30%;
  }

}